import classNames from "clsx";
import { FC } from "react";

import { SpinnerThirdIcon } from "../core/Icon";

type LoadingSpinnerProps = {
  loadingText?: string;
  className?: string;
};

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ loadingText = "Loading…", className }) => {
  return (
    <div
      className={classNames(
        "flex flex-col justify-center items-center h-full w-full text-center",
        className
      )}
    >
      <SpinnerThirdIcon size="2x" spin className="text-label" />
      <p className="mt-2 text-sm font-medium text-label">{loadingText}</p>
    </div>
  );
};

export default LoadingSpinner;
