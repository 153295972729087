import type { AppMeta } from "@packages/services/src/http/get-app";
import { UserProfilePayload } from "@packages/services/src/http/get-me";
import type { UserInvite } from "@packages/services/src/shared/models/UserInvite";
import { PendingContactRow } from "@shared/models/PendingContact";
import { RecurringTaskTableRow } from "@shared/models/RecurringTask";
import { RemoteApiForDisplay } from "@shared/models/RemoteApi";
import { RemoteApiSnapshot } from "@shared/models/RemoteApiSnapshot";
import { EventType } from "@shared/models/types";
import { UserEvent } from "@shared/models/UserEvent";
import { appApi } from "@web/integrations/app/api";
import { queryFetch } from "@web/integrations/redux/baseQuery";

import userSlice from "./slice";

export async function fetchPaginatedPendingContacts(
  remoteApiId: string,
  startKey: string | undefined
) {
  const uri = `/me/integrations/${remoteApiId}/pendingContacts`;
  const { data, headers, error } = await queryFetch<PendingContactRow[]>(
    startKey ? `${uri}?startKey=${startKey}` : uri
  );

  return { startKey: headers?.get("next-start-key"), error, data };
}

const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getApp: builder.query<AppMeta, void>({
      query: () => "/app",
    }),
    getUser: builder.query<UserProfilePayload, void>({
      query: () => "/me",
      providesTags: ["USER", "INTEGRATIONS", "USER_DELEGATIONS"],
      async onCacheEntryAdded(_arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        dispatch(
          userSlice.actions.validateCurrentDelegatedUser({
            userDelegations: data?.userDelegations || [],
          })
        );
      },
    }),
    getIntegration: builder.query<
      { integration: RemoteApiForDisplay; task: RecurringTaskTableRow | undefined },
      string
    >({
      query: (remoteApiId: string) => `/me/integrations/${remoteApiId}`,
    }),
    getPendingContacts: builder.query<PendingContactRow[], string>({
      async queryFn(remoteApiId: string) {
        const initResult = await fetchPaginatedPendingContacts(remoteApiId, undefined);
        let count = 1;
        if (initResult.error) return { data: [], error: initResult.error };
        let pendingContacts: PendingContactRow[] = initResult.data || [];

        let startKey = initResult.startKey;

        while (count < 20 && startKey) {
          const result = await fetchPaginatedPendingContacts(remoteApiId, startKey);
          count++;
          if (result.error) return { data: [], error: result.error };
          pendingContacts = pendingContacts.concat(result.data || []);
          startKey = result.startKey;
        }

        return { data: pendingContacts, error: undefined };
      },
    }),
    getUserEvents: builder.query<UserEvent[], { createdDaySince?: string }>({
      query: ({ createdDaySince }) => {
        const uri = "/me/userEvents";
        return createdDaySince ? `${uri}?createdDaySince=${createdDaySince}` : uri;
      },
      providesTags: ["USER_EVENTS"],
    }),
    addUserEvents: builder.mutation<
      void,
      {
        entityId: string;
        createdAt: number;
        type: EventType;
      }[]
    >({
      query: (body) => ({
        url: "/me/userEvents",
        method: "POST",
        body,
      }),
      invalidatesTags: ["USER_EVENTS"],
    }),
    updateIntegration: builder.mutation<
      RemoteApiForDisplay,
      Pick<RemoteApiForDisplay, "id"> & Partial<RemoteApiForDisplay>
    >({
      query: (integration) => ({
        url: `/me/integrations/${integration.id}`,
        method: "POST",
        body: integration,
      }),
      invalidatesTags: ["INTEGRATIONS"],
      async onQueryStarted({ id, ...update }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData("getUser", undefined, (draft) => {
            for (const index in draft.integrations || []) {
              if (draft.integrations[index].id === id) {
                draft.integrations[index] = { ...draft.integrations[index], ...update };
              }
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    createIntegrationFromGoogleOauth: builder.mutation<void, string>({
      query: (code: string) => ({
        url: "/me/integrations/oauth/google",
        method: "POST",
        body: { code },
      }),
      invalidatesTags: ["INTEGRATIONS"],
      extraOptions: { maxRetries: 0 },
    }),
    createIntegrationFromIcloudPassword: builder.mutation<
      void,
      { email: string; appSpecificPassword: string }
    >({
      query: ({ email, appSpecificPassword }) => ({
        url: "/me/integrations/password/icloud",
        method: "POST",
        body: { email, appSpecificPassword },
      }),
      invalidatesTags: ["INTEGRATIONS"],
      extraOptions: { maxRetries: 0 },
    }),
    deleteIntegration: builder.mutation<void, string>({
      query: (remoteApiId: string) => ({
        url: `/me/integrations/${remoteApiId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["INTEGRATIONS"],
    }),
    getSnapshots: builder.query<RemoteApiSnapshot[], { integrationId: string; limit?: number }>({
      query: ({ integrationId, limit }) => {
        const url = `/me/snapshots/integrations/${integrationId}`;
        return limit ? `${url}?$limit=${limit}` : url;
      },
      providesTags: ["SNAPSHOTS"],
    }),
    getAllSnapshots: builder.query<
      (RemoteApiForDisplay & { snapshots: RemoteApiSnapshot[] })[],
      number
    >({
      query: (limit) => {
        const url = "/me/snapshots/integrations";
        return limit ? `${url}?limit=${limit}` : url;
      },
      providesTags: ["ALL_SNAPSHOTS"],
    }),
    restoreSnapshot: builder.mutation<void, { integrationId: string; createdAt: number }>({
      query: ({ integrationId, createdAt }) => ({
        url: `/me/integrations/${integrationId}/restore`,
        method: "POST",
        body: {
          createdAt,
        },
      }),
    }),
    inviteCollaborator: builder.mutation<void, Omit<UserInvite, "userId" | "id" | "updatedAt">>({
      query: (invite) => ({
        url: `/me/invites`,
        method: "POST",
        body: { ...invite },
      }),
      invalidatesTags: ["INVITES"],
    }),
    getInvites: builder.query<UserInvite[], void>({
      query: () => "/me/invites",
      providesTags: ["INVITES"],
    }),
    updateUserTimeZone: builder.mutation<void, string>({
      query: (timeZone) => ({
        url: "/me/timezone",
        method: "POST",
        body: { timeZone },
      }),
      invalidatesTags: ["USER"],
    }),
  }),
});

export default userApi;

export const {
  useGetAppQuery,
  useGetPendingContactsQuery,
  useGetIntegrationQuery,
  useGetInvitesQuery,
  useGetUserQuery,
  useGetUserEventsQuery,
  useAddUserEventsMutation,
  useLazyGetUserQuery,
  useGetSnapshotsQuery,
  useRestoreSnapshotMutation,
  useGetAllSnapshotsQuery,
  useInviteCollaboratorMutation,
  useUpdateIntegrationMutation,
  useCreateIntegrationFromGoogleOauthMutation,
  useCreateIntegrationFromIcloudPasswordMutation,
  useDeleteIntegrationMutation,
  useUpdateUserTimeZoneMutation,
} = userApi;
