import Bugsnag from "@bugsnag/js";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";
import smartlookClient from "smartlook-client";

import {
  selectAuthenticatedSession,
  selectAuthExplictlySignedOut,
} from "../integrations/auth/selectors";
import { signIn } from "../integrations/auth/slice";
import { auth } from "../integrations/firebase";
import { useAppDispatch } from "../integrations/redux/store";
import appPackage from "../package.json";

const useAuth = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const session = useSelector(selectAuthenticatedSession);
  const explicitlySignedOut = useSelector(selectAuthExplictlySignedOut);

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (user && !error) {
      dispatch(signIn(user));
    } else if ((explicitlySignedOut || !user) && !loading && router.route !== "/signin") {
      router.push("/signin");
    }
  }, [error, explicitlySignedOut, loading, router, user]);

  useEffect(() => {
    if (session) {
      Bugsnag.setUser(session.sub!, session.email!);
      smartlookClient.identify(session.sub!, {
        email: session.email!,
        appVersion: appPackage.version,
      });
    }
  }, [session]);

  return { session, authUser: user, loading, error };
};

export default useAuth;
