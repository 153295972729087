import {
  faApple,
  faFacebook,
  faFlickr,
  faGoogle,
  faInstagram,
  faLinkedin,
  faTiktok,
  faTwitter,
  faWeibo,
  faWeixin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAddressCard,
  faBars,
  faBirthdayCake,
  faBriefcase,
  faCalendar,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCloud,
  faCloudCheck,
  faCloudDownload,
  faCloudPlus,
  faCloudXmark,
  faCommentsAlt,
  faDoNotEnter,
  faEllipsisV,
  faEnvelope,
  faFileSpreadsheet,
  faGlobe,
  faHashtag,
  faKeyboard,
  faLink,
  faMapMarkerAlt,
  faMerge,
  faPen,
  faPhone,
  faPlus,
  faSearch,
  faSpinnerThird,
  faStar,
  faStopwatch,
  faTag,
  faTimes,
  faTrash,
  faUserAlt,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowRightArrowLeft as faArrowRightArrowLeftSolid,
  faBell as faBellSolid,
  faBookmark as faBookmarkSolid,
  faCalendar as faCalendarSolid,
  faCheckCircle as faCheckCircleSolid,
  faCircle as faCircleSolid,
  faCloud as faCloudSolid,
  faCloudXmark as faCloudXMarkSolid,
  faCog as faCogSolid,
  faCopy as faCopySolid,
  faDotCircle as faDotCircleSolid,
  faEdit as faEditSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faExternalLink as faExternalLinkSolid,
  faFilter as faFilterSolid,
  faHistory as faHistorySolid,
  faHome as faHomeSolid,
  faInfoCircle as faInfoCircleSolid,
  faLeftLong as faLeftLongSolid,
  faLockKeyhole as faLockKeyholeSolid,
  faLockKeyholeOpen as faLockKeyholeOpenSolid,
  faMapMarkedAlt as faMapMarkedAltSolid,
  faMinusCircle as faMinusCircleSolid,
  faMoon as faMoonSolid,
  faPaperPlane as faPaperPlaneSolid,
  faPen as faPenSolid,
  faPhone as faPhoneSolid,
  faPlusCircle as faPlusCircleSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faSortAmountDown as faSortAmountDownSolid,
  faSortCircle as faSortCircleSolid,
  faStar as faStarSolid,
  faStopCircle as faStopCircleSolid,
  faSun as faSunSolid,
  faTag as faTagSolid,
  faTimesCircle as faTimesCircleSolid,
  faTools as faToolsSolid,
  faTriangleExclamation as faTriangleExclamationSolid,
  faUser as faUserSolid,
  faUserCircle as faUserCircleSolid,
  faUserFriends as faUserFriendsSolid,
  faUsers as faUsersSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React from "react";

export type IconType = (props?: Partial<FontAwesomeIconProps>) => JSX.Element;

export const AppleIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faApple} {...props} />
);

export const AddressCardIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faAddressCard} {...props} />
);

export const ArrowRightArrowLeftSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faArrowRightArrowLeftSolid} {...props} />
);

export const BarsIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBars} {...props} />
);

export const BellSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBellSolid} {...props} />
);

export const BirthdayCakeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBirthdayCake} {...props} />
);

export const BookmarkIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBookmarkSolid} {...props} />
);

export const BriefcaseIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBriefcase} {...props} />
);

export const CalendarIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCalendar} {...props} />
);

export const CalendarSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCalendarSolid} {...props} />
);

export const CheckIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCheck} {...props} />
);

export const CheckCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCheckCircleSolid} {...props} />
);

export const ChevronDownIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faChevronDown} {...props} />
);

export const ChevronRightIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faChevronRight} {...props} />
);

export const ChevronLeftIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faChevronLeft} {...props} />
);

export const CircleIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCircle} {...props} />
);

export const CircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCircleSolid} {...props} />
);

export const CloudSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudSolid} {...props} />
);

export const CloudXMarkSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudXMarkSolid} {...props} />
);

export const CloudDownloadIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudDownload} {...props} />
);

export const CloudIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloud} {...props} />
);

export const CloudXIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudXmark} {...props} />
);

export const CloudXIconSolid = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudXMarkSolid} {...props} />
);

export const CloudCheckIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudCheck} {...props} />
);

export const CloudPlusIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudPlus} {...props} />
);

export const CommentsAltIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCommentsAlt} {...props} />
);

export const CogSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCogSolid} {...props} />
);

export const CopySolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCopySolid} {...props} />
);

export const DotCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faDotCircleSolid} {...props} />
);

export const DoNotEnterIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faDoNotEnter} {...props} />
);

export const EditSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faEditSolid} {...props} />
);

export const EllipsisVIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faEllipsisV} {...props} />
);

export const EnvelopeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faEnvelope} {...props} />
);

export const ExclamationCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faExclamationCircleSolid} {...props} />
);

export const ExternalLinkSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faExternalLinkSolid} {...props} />
);

export const FacebookIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFacebook} {...props} />
);

export const FilterSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFilterSolid} {...props} />
);

export const FlickrIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFlickr} {...props} />
);

export const GlobeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faGlobe} {...props} />
);

export const GoogleIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faGoogle} {...props} />
);

export const HashtagIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faHashtag} {...props} />
);

export const HistorySolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faHistorySolid} {...props} />
);

export const HomeSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faHomeSolid} {...props} />
);

export const InstagramIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faInstagram} {...props} />
);

export const InfoCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faInfoCircleSolid} {...props} />
);

export const KeyboardIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faKeyboard} {...props} />
);

export const LeftLongSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLeftLongSolid} {...props} />
);

export const LockSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLockKeyholeSolid} {...props} />
);

export const LockOpenSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLockKeyholeOpenSolid} {...props} />
);

export const LinkIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLink} {...props} />
);

export const LinkedinIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLinkedin} {...props} />
);

export const MapMarkerAltIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMapMarkerAlt} {...props} />
);

export const MapMarkedAltSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMapMarkedAltSolid} {...props} />
);

export const MergeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMerge} {...props} />
);

export const MinusCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMinusCircleSolid} {...props} />
);

export const MoonSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMoonSolid} {...props} />
);

export const PaperPlaneSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPaperPlaneSolid} {...props} />
);

export const PenIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPen} {...props} />
);

export const PenSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPenSolid} {...props} />
);

export const PhoneIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPhone} {...props} />
);

export const PhoneSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPhoneSolid} {...props} />
);

export const PlusIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPlus} {...props} />
);

export const PlusCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPlusCircleSolid} {...props} />
);

export const QuestionCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faQuestionCircleSolid} {...props} />
);

export const ReplaceCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSortCircleSolid} rotation={90} {...props} />
);

export const SearchIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSearch} {...props} />
);

export const SortCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSortCircleSolid} {...props} />
);

export const SortAmountDownSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSortAmountDownSolid} {...props} />
);

export const SpinnerThirdIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSpinnerThird} {...props} />
);

export const StarIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faStar} {...props} />
);

export const SpreadsheetIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFileSpreadsheet} {...props} />
);

export const StarSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faStarSolid} {...props} />
);

export const StopCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faStopCircleSolid} {...props} />
);

export const StopwatchIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faStopwatch} {...props} />
);

export const SunSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSunSolid} {...props} />
);

export const TagIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTag} {...props} />
);

export const TagSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTagSolid} {...props} />
);

export const ToolsSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faToolsSolid} {...props} />
);

export const TikTokIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTiktok} {...props} />
);

export const TimesIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTimes} {...props} />
);

export const TimesCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTimesCircleSolid} {...props} />
);

export const TrashIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTrash} {...props} />
);

export const TriangleExclamationSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTriangleExclamationSolid} {...props} />
);

export const TwitterIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTwitter} {...props} />
);

export const UserAltIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserAlt} {...props} />
);

export const UserCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserCircleSolid} {...props} />
);

export const UserFriendsSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserFriendsSolid} {...props} />
);

export const UsersIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUsers} {...props} />
);

export const UserInvite = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserPlus} {...props} />
);

export const UserSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserSolid} {...props} />
);

export const UsersSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUsersSolid} {...props} />
);

export const WeiboIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faWeibo} {...props} />
);

export const WeixinIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faWeixin} {...props} />
);

export const YoutubeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faYoutube} {...props} />
);
